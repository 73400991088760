import { get } from "../utilis/api/wrappers";
import { camelizeKeys } from "../utilis/api/helpers";
import { GET } from "../utilis/api/endpoints";
import { API_Comment } from "../../types";
import { ref, computed, ComputedRef, reactive } from "vue";

export default function fetchComments(contentType: string, id: number|undefined) {
  const comments = ref<API_Comment[]>([]);
  const loading = ref<boolean>(true);
  
  const fetchComments = () => {
    get<API_Comment[]>(GET.COMMENTS + `/${contentType}:${id}`).then(
      (result) => {
        loading.value = false;
        comments.value = camelizeKeys(result);
      }
    );
  };
  fetchComments();

  return { comments, loading, fetchComments };
}
