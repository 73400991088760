
import { ValidateErrorEntity } from "ant-design-vue/es/form/interface";
import {
  defineComponent,
  reactive,
  ref,
  UnwrapRef,
  PropType,
  watch,
} from "vue";
import submitComment from "../../misc/composition/postComment";
import { API_Comment, CommentCTX } from "../../types";

export default defineComponent({
  setup(props, { emit }) {
    const formRef = ref();
    const formState: UnwrapRef<API_Comment> = reactive({
      authorId: 0,
      authorName: "",
      authorEmail: "",
      authorWebsite: "",
      content: "",
      threadOf: props.ctx?.id, // id of comment we would like to start / continue the thread (Optional)
      related: [
        {
          refId: 0,
          ref: props.ctx?.contentType ?? "blog",
          field: "comments",
        },
      ],
    });
    const rules = {
      authorName: [
        {
          required: true,
          message: "Please complete your name",
          trigger: "blur",
        },
        {
          min: 2,
          message: "Length should be greatear than 2",
          trigger: "blur",
        },
      ],
      authorWebsite: [
        {
          required: false,
          message: "Please complete your website",
          trigger: "blur",
        },
      ],
      authorEmail: [
        {
          required: true,
          message: "Please complete your email",
          trigger: "blur",
          type: "email",
        },
        { type: "email", message: "Invalid email format", trigger: "blur" },
      ],
      content: [
        {
          required: true,
          message: "Please complete your comment",
          trigger: "blur",
        },
      ],
    };
    const onSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          const { postComment, loading: isCommentLoading } = submitComment(
            formRef.value.getFieldsValue(),
            props.ctx
          );
          postComment();
          watch(isCommentLoading, () => {
            if (!isCommentLoading.value) {
              emit("onRefreshComments");
            }
          });
        })
        .catch((error: ValidateErrorEntity<API_Comment>) => {
          console.log("error", error);
        });
    };
    const resetForm = () => {
      formRef.value.resetFields();
    };
    return {
      formRef,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      other: "",
      formState,
      rules,
      onSubmit,
      resetForm,
    };
  },
  props: {
    isReply: {
      type: Boolean,
      default: false,
    },
    ctx: {
      type: Object as PropType<CommentCTX>,
      required: true,
    },
    replyAuthorName: {
      type: String,
    },
  },
});
