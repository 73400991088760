
import { defineComponent, PropType } from "vue";
import { Comments, Thumbnail, CommentCTX } from "../../types";
import CommentBox from "../CommentBox/CommentBox.vue";

export default defineComponent({
  components: {
    CommentBox,
  },
  props: {
    ctx: {
      type: Object as PropType<CommentCTX>,
      required: true,
    },
    content: {
      type: String,
      required: false,
    },
    authorName: {
      type: String,
      required: false,
    },
    createdAt: {
      type: String,
      required: false,
    },
    thumbnail: {
      type: Object as PropType<Thumbnail>,
      required: false,
    },
    comments: {
      type: Object as PropType<Comments[]>,
    },
    blockedThread: {
      type: Boolean
    }
  },
  data() {
    return {
      replyBox: false,
    };
  },
});
