import { post } from "../utilis/api/wrappers";
import { camelizeKeys } from "../utilis/api/helpers";
import { POST } from "../utilis/api/endpoints";
import { Casino, API_Comment, CommentCTX, Rating, ProCons } from "../../types";
import { ref, computed, ComputedRef } from "vue";
import { message } from "ant-design-vue";

export default function submitComment(
  payload: API_Comment,
  ctx: CommentCTX,
) {
  //   const casino = ref<Casino>({} as Casino);
  const loading = ref<boolean>(true);
  payload.threadOf = ctx.id;
  payload.related = [
    {
      refId: ctx.contentID,
      ref: ctx.contentType,
      field: "comments",
    },
  ];

  const postComment = () => {
    post<API_Comment>(POST.COMMENT + `/${ctx.contentType}:${ctx.contentID}`, payload, {
      headers: { "Content-Type": "application/json" },
    }).then((result) => {
      if (result.status === 200) {
        loading.value = false;
        message.success("Your comment is awaiting approval");
      } else {
        message.error("Your comment could not be posted! Try again later.");
      }
    });
  };

  return {
    postComment,
    loading,
  };
}
